/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import Widget from "gatsby-theme-pages/src/components/Widget";
import type { Props } from "gatsby-theme-pages/src/components/Widget/Columns";
import WidgetWrapper from "gatsby-theme-pages/src/components/WidgetWrapper";
import React, { memo } from "react";
import { Grid, jsx } from "theme-ui";

import Wrapper from "../Section/Wrapper";

const ColumnsWidget: React.FC<Props> = ({
  data,
  isNested,
  isFirst,
  isLast,
  widget,
  prevWidget,
  nextWidget,
}) => {
  const shape = widget.columnsShape;
  const columnSizes = shape?.columnSizes || [50, 50];

  const GridComponent = (
    <Grid
      gap={responsive({ xs: 4, lg: 5 })}
      columns={responsive({
        xs: "100%",
        lg: columnSizes.reduce((curr, next) => `${curr}${next}fr `, ""),
      })}
    >
      {shape?.columns?.map((column, index) => {
        return (
          <div key={index} sx={{ minWidth: 0 }}>
            {column?.widgets?.map((nestedWidget, nestedWidgetIndex) => {
              if (!nestedWidget) {
                return null;
              }

              return (
                <Widget
                  key={nestedWidget.id}
                  widget={nestedWidget}
                  isFirst={nestedWidgetIndex === 0}
                  isLast={
                    nestedWidgetIndex === (column.widgets?.length || 0) - 1
                  }
                  isNested={true}
                  shouldBleed={
                    (shape?.backgroundColor !== "transparent" ||
                      !!shape?.border) &&
                    shape?.containerSize === "FULL"
                  }
                  containerSize={"STANDARD"}
                  data={data}
                />
              );
            })}
          </div>
        );
      })}
    </Grid>
  );

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={shape?.wrapperSize || "STANDARD"}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
      contained={false}
    >
      <Wrapper
        wrapperSize={shape?.wrapperSize || "STANDARD"}
        containerSize={shape?.containerSize || "STANDARD"}
        backgroundColor={shape?.backgroundColor || "transparent"}
        border={shape?.border || false}
      >
        {GridComponent}
      </Wrapper>
    </WidgetWrapper>
  );
};

export default memo(ColumnsWidget);
